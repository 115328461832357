import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import logo from './imgs/logo-horizontal-rvb-pour-fond-clair.webp';
import iconAddClient from './imgs/icon-client.svg';
import iconAddCharge from './imgs/icon-add-charge.svg';
import iconWebsite from './imgs/icon-add-website.svg';
import iconLogout from './imgs/logout-box-r-line.svg';
import './style.css';
import { useNavigate } from 'react-router-dom';
import { LogOutUserService } from '../../../services/AuthService';

const HomeMenu = () => {
  let navigate = useNavigate();
  return (
    <Card className={'min-vh-100 menu-card-head h-100'}>
      <a
        onClick={() => {
          navigate('/');
        }}
        className={'cursor-pointer'}
      >
        <Card.Img variant={'top'} src={logo} className={'menu-card-img'} />
      </a>
      <Card.Body>
        <a
          onClick={() => {
            navigate('/client');
          }}
          className="menu-link cursor-pointer"
        >
          <Row className={'mb-2'}>
            <Col lg={1}>
              <img srcSet={iconAddClient} />
            </Col>
            <Col>
              <p className={'menu-link'}>Entreprise</p>
            </Col>
          </Row>
        </a>
        <a
          onClick={() => {
            navigate('/website');
          }}
          className="menu-link cursor-pointer"
        >
          <Row className={'mb-2'}>
            <Col lg={1}>
              <img srcSet={iconWebsite} />
            </Col>
            <Col>
              <p className={'menu-link'}>Site internet</p>
            </Col>
          </Row>
        </a>
        <a
          onClick={() => {
            navigate('/charge');
          }}
          className="menu-link cursor-pointer"
        >
          <Row className={'mb-2'}>
            <Col lg={1}>
              <img srcSet={iconAddCharge} />
            </Col>
            <Col>
              <p>Charge</p>
            </Col>
          </Row>
        </a>
        <a
          onClick={() => {
            LogOutUserService();
          }}
          className="menu-link cursor-pointer"
        >
          <Row className={'mb-2'}>
            <Col lg={1}>
              <img srcSet={iconLogout} />
            </Col>
            <Col>
              <p>Déconnexion</p>
            </Col>
          </Row>
        </a>
      </Card.Body>
    </Card>
  );
};

export default HomeMenu;
