import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import RequireAuthAdmin from '../../../middlewares/RequireAuthAdmin';

const AdminLayout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    RequireAuthAdmin(navigate);
  }, []);
  return <Outlet />;
};

export default AdminLayout;
