import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormControl,
  Offcanvas,
  Row
} from 'react-bootstrap';
import FormAlert from '../../common/FormAlert';
import ManageCompany from '../../client/manage/ManageCompany';
import Select from 'react-select';
import PropTypes from 'prop-types';
import ActionButton from '../../../common/ActionButton';
import { PostWebsite, PutWebsite } from '../../../../services/WebsiteService';
import { toast } from 'react-toastify';
import { IndexCompanies } from '../../../../services/CompanyService';

const ManageWebsite = ({ action, setTrigger, selectedObject }) => {
  const [showAddWebsite, setShowAddWebsite] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [optionsSearchClient, setOptionsSearchClient] = useState([]);
  const [triggerCompanies, setTriggerCompanies] = useState(Date.now());
  const [formData, setFormData] = useState({
    company_id: '',
    frontUrl: '',
    backUrl: '',
    note: ''
  });
  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    let response = null;
    if (selectedObject.id) {
      response = await PutWebsite(selectedObject.id, formData);
    } else {
      response = await PostWebsite(formData);
    }
    if (response.success === true) {
      setShowAddWebsite(false);
      setIsLoading(false);
      setFormData({
        company_id: '',
        frontUrl: '',
        backUrl: '',
        note: ''
      });
      setErrorForm(false);
      toast('Site internet ajoutée avec succès');
      setTrigger(Date.now());
    } else if (response.validator) {
      setIsLoading(false);
      setErrorForm(response.validator);
    }
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      const responseObjects = await IndexCompanies();
      if (responseObjects.success === true) {
        let tmpWebsites = [];
        responseObjects.data.map(r => {
          tmpWebsites.push({
            value: r.id,
            label: r.name
          });
        });
        setOptionsSearchClient(tmpWebsites);
      }
    };

    fetchCompanies();
  }, [triggerCompanies]);

  return (
    <>
      {action === 'add' ? (
        <Button
          variant="primary"
          size="sm"
          icon="edit"
          transform="shrink-3"
          disabled={isLoading}
          onClick={() => {
            setShowAddWebsite(true);
          }}
        >
          Ajouter un site internet
        </Button>
      ) : action === 'edit' ? (
        <ActionButton
          icon="edit"
          title="Modifier"
          variant="action"
          className="p-0 me-2"
          onClick={() => {
            setShowAddWebsite(true);
            setFormData({
              company_id: selectedObject.company_id,
              frontUrl: selectedObject.frontUrl,
              backUrl: selectedObject.backUrl,
              note: selectedObject.note
            });
          }}
        />
      ) : (
        ''
      )}

      <Offcanvas
        show={showAddWebsite}
        onHide={() => {
          setShowAddWebsite(false);
          setFormData({
            company_id: '',
            frontUrl: '',
            backUrl: '',
            note: ''
          });
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {selectedObject.id
              ? 'Modifier le site internet'
              : 'Ajouter un site internet'}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {errorForm && (
            <div className="mt-3">
              <FormAlert variant="danger" data={errorForm} />
            </div>
          )}
          {!selectedObject.id && (
            <>
              <Row>
                <Col lg={12} style={{ textAlign: 'right' }}>
                  <ManageCompany
                    setTrigger={setTriggerCompanies}
                    selectedObject={{}}
                    action={'add'}
                  />
                </Col>
              </Row>
              <Form.Group>
                <Form.Label>
                  Sélectionner une entreprise <span>*</span>
                </Form.Label>
                <Select
                  closeMenuOnSelect={true}
                  options={optionsSearchClient}
                  placeholder="Sélectionner une entreprise..."
                  onChange={value => {
                    setFormData({ ...formData, company_id: value.value });
                  }}
                />
              </Form.Group>
            </>
          )}
          <Form.Group className={!selectedObject.id && 'mt-3'}>
            <Form.Label>
              Url site <span>*</span>
            </Form.Label>
            <FormControl
              type={'text'}
              onChange={handleFieldChange}
              value={formData.frontUrl}
              name={'frontUrl'}
            ></FormControl>
          </Form.Group>
          <Form.Group className={'mt-3'}>
            <Form.Label>
              Url site (back office) <span>*</span>
            </Form.Label>
            <FormControl
              type={'text'}
              onChange={handleFieldChange}
              value={formData.backUrl}
              name={'backUrl'}
            ></FormControl>
          </Form.Group>
          <Form.Group className={'mt-3'}>
            <Form.Label>
              Notes <span>*</span>
            </Form.Label>
            <FormControl
              as="textarea"
              onChange={handleFieldChange}
              value={formData.note}
              name={'note'}
            />
          </Form.Group>
          <Row className={'mt-3'}>
            <Col lg={12} style={{ textAlign: 'right' }}>
              <a>
                <Button
                  variant="primary"
                  size="sm"
                  icon="edit"
                  transform="shrink-3"
                  disabled={isLoading}
                  onClick={handleSubmit}
                >
                  Enregistrer le site internet
                </Button>
              </a>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

ManageWebsite.propTypes = {
  action: PropTypes.string.isRequired,
  setTrigger: PropTypes.func.isRequired,
  selectedObject: PropTypes.object.isRequired
};

export default ManageWebsite;
