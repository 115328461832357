import React, { useEffect, useRef, useState } from 'react';
import { Accordion, Card, Col, Container, Form, Row } from 'react-bootstrap';
import Flex from '../../common/Flex';
import './style.css';
import HomeTableExpiry from './HomeTableExpiry';
import HomeTableCalendar from './HomeTableCalendar';
import HomeMenu from './HomeMenu';
import moment from 'moment';
import { IndexBenefits } from '../../../services/BenefitService';
import PageHeader from '../../common/PageHeader';
import { IndexCharges } from '../../../services/ChargeService';
import CountUp from 'react-countup';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getColor, rgbaColor, tooltipFormatter } from '../../../helpers/utils';
import * as echarts from 'echarts/core';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer,
  LegendComponent
]);
const HomeLayout = () => {
  const [benefits, setBenefits] = useState([]);
  const [charges, setCharges] = useState([]);
  const [years, setYears] = useState([]);
  const [year, setYear] = useState(0);
  const [trigger, setTrigger] = useState(Date.now());

  const chartRef = useRef(null);

  const getOptionRecap = () => ({
    legend: {
      left: 'left',
      textStyle: {
        color: getColor('gray-600')
      }
    },
    series: [
      {
        type: 'pie',
        radius: window.innerWidth < 530 ? '45%' : '60%',
        label: {
          color: getColor('gray-700')
        },
        center: ['50%', '55%'],
        data: [
          {
            value: parseFloat(getSeles()).toFixed(2),
            name: 'Ventes',
            itemStyle: {
              color: getColor('info')
            }
          },
          {
            value: parseFloat(getPricesCharges()).toFixed(2),
            name: 'Charges',
            itemStyle: {
              color: getColor('danger')
            }
          },
          {
            value: parseFloat(calculationProfit(benefits, charges)).toFixed(2),
            name: 'Bénéfices',
            itemStyle: {
              color: getColor('success')
            }
          }
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: rgbaColor(getColor('gray-600'), 0.5)
          }
        }
      }
    ],
    tooltip: {
      trigger: 'item',
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      }
    }
  });

  const updateDimensions = () => {
    if (window.innerWidth < 530) {
      chartRef.current.getEchartsInstance().setOption({
        series: [{ radius: '45%' }]
      });
    } else
      chartRef.current.getEchartsInstance().setOption({
        series: [{ radius: '60%' }]
      });
  };

  const months = [
    'Décembre',
    'Novembre',
    'Octobre',
    'Septembre',
    'Août',
    'Juillet',
    'Juin',
    'Mai',
    'Avril',
    'Mars',
    'Février',
    'Janvier'
  ];

  const data = [
    1272, 1301, 1402, 1216, 1086, 1236, 1219, 1330, 1367, 1416, 1297, 1204
  ];

  const getOptionInterventions = () => ({
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: tooltipFormatter,
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      }
    },
    xAxis: {
      type: 'value',
      boundaryGap: '0%',
      axisLine: {
        show: true,
        lineStyle: {
          color: getColor('gray-300')
        }
      },
      axisTick: { show: true },
      axisLabel: {
        color: getColor('gray-500')
      },
      splitLine: {
        show: false
      },
      min: 0
    },
    yAxis: {
      type: 'category',
      data: months,
      boundaryGap: '0%',
      axisLabel: {
        formatter: value => value.substring(0, 3),
        show: true,
        color: getColor('gray-500'),
        margin: 15
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: getColor('gray-200')
        }
      },
      axisTick: { show: false },
      axisLine: {
        lineStyle: {
          color: getColor('gray-300')
        }
      }
    },
    series: [
      {
        type: 'bar',
        name: 'Total',
        data,
        lineStyle: { color: getColor('primary') },
        itemStyle: {
          color: getColor('primary'),
          borderRadius: [0, 3, 3, 0]
        },
        showSymbol: false,
        symbol: 'circle',
        smooth: false,
        emphasis: {
          scale: true
        }
      }
    ],
    grid: { right: '3%', left: '10%', bottom: '10%', top: '5%' }
  });

  function createArrayYears(benefit) {
    let tmpYears = benefit
      .map(benefit => parseInt(moment(benefit.billingDateStart).format('Y')))
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort((a, b) => b - a);
    setYears(tmpYears);
  }

  function calculationProfit(benefits, charges) {
    let sumBenefit = benefits.reduce((sumPriceBenefit, object) => {
      if (year === moment(object.billingDateStart).year()) {
        return sumPriceBenefit + parseFloat(object.amount);
      } else {
        return sumPriceBenefit;
      }
    }, 0);

    let sumCharge = charges.reduce((accumulator, object) => {
      if (year === moment(object.created_at).year()) {
        if (object.frequency === 'monthly') {
          return accumulator + parseFloat(object.amount) * 12;
        } else {
          return accumulator + parseFloat(object.amount);
        }
      } else {
        return accumulator;
      }
    }, 0);

    return sumBenefit - sumCharge;
  }

  function getSeles() {
    return benefits.reduce((sumPriceBenefit, object) => {
      if (year === moment(object.billingDateStart).year()) {
        return sumPriceBenefit + parseFloat(object.amount);
      } else {
        return sumPriceBenefit;
      }
    }, 0);
  }

  function getPricesCharges() {
    return charges.reduce((sumPriceBenefit, object) => {
      if (year === moment(object.created_at).year()) {
        if (object.frequency === 'monthly') {
          return sumPriceBenefit + parseFloat(object.amount) * 12;
        } else {
          return sumPriceBenefit + parseFloat(object.amount);
        }
      } else {
        return sumPriceBenefit;
      }
    }, 0);
  }

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    window.removeEventListener('resize', updateDimensions);

    const fetchBenefits = async () => {
      const responseObjects = await IndexBenefits();
      if (responseObjects.success === true) {
        setBenefits(responseObjects.data);
        createArrayYears(responseObjects.data);
      }
    };

    const fetchCharges = async () => {
      const responseObjects = await IndexCharges();
      if (responseObjects.success === true) {
        setCharges(responseObjects.data);
      }
    };
    fetchCharges();
    fetchBenefits();
    setYear(moment().year());
  }, [trigger]);

  return (
    <Container fluid={Flex}>
      <Row>
        <Col lg={2} className={'p-0 w-auto'}>
          <HomeMenu />
        </Col>
        <Col className={'main-right'}>
          <Row className={'mt-3'}>
            <Col>
              <Card className={'text-center'}>
                <h3 className={'m-3'}>Gestionnaire des maintenances</h3>
              </Card>
            </Col>
          </Row>
          <br />
          {benefits.length > 0 && years.length > 0 && (
            <Row>
              <Col>
                <Card className={'p-3'}>
                  <p className={'pb-2'}>
                    <strong>
                      <u>Sélectionner une année</u>
                    </strong>
                  </p>
                  <Form.Group>
                    <Form.Select
                      onChange={e => {
                        setYear(parseInt(e.target.value));
                      }}
                    >
                      {years.map((currentYear, index) => (
                        <option value={currentYear} key={index}>
                          {currentYear}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Card>
              </Col>
            </Row>
          )}
          <br />
          <Row>
            <Col>
              <PageHeader title={'Ventes'}>
                <span className={'recap-color-sales'}>
                  <CountUp
                    end={getSeles()}
                    duration={1}
                    suffix="€"
                    decimals={2}
                  />
                </span>
              </PageHeader>
            </Col>
            <Col>
              <PageHeader title={'Charges'}>
                <span className={'recap-color-charges'}>
                  <CountUp
                    end={getPricesCharges()}
                    duration={1}
                    suffix="€"
                    decimals={2}
                  />
                </span>
              </PageHeader>
            </Col>
            <Col>
              <PageHeader title={'Bénéfices'}>
                <span className={'recap-color-profit'}>
                  <CountUp
                    end={calculationProfit(benefits, charges)}
                    duration={1}
                    suffix="€"
                    decimals={2}
                  />
                </span>
              </PageHeader>
            </Col>
          </Row>
          <br />
          <Row>
            <Accordion defaultActiveKey={0}>
              <Accordion.Item eventKey={0}>
                <Accordion.Header>
                  <Card.Title as="h5">Récapitulatif</Card.Title>
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col>
                      <Card className={'p-3 h-100'}>
                        <ReactEChartsCore
                          echarts={echarts}
                          option={getOptionRecap()}
                          ref={chartRef}
                          style={{ height: '20rem' }}
                        />
                      </Card>
                    </Col>
                    <Col>
                      <Card className={'p-3 h-100'}>
                        <Card.Title as={'h6'}>Interventions</Card.Title>
                        <ReactEChartsCore
                          echarts={echarts}
                          option={getOptionInterventions()}
                          style={{ height: '18.75rem' }}
                        />
                      </Card>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Row>
          <br />
          <Row>
            <Col>
              <Card className={'p-3'}>
                <p className={'pb-3'}>
                  <strong>
                    <u>
                      Prévisualisation des prestations qui expire dans 60 jours
                    </u>
                  </strong>
                </p>
                <HomeTableExpiry benefits={benefits} setTrigger={setTrigger} />
              </Card>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Card className={'p-3 mb-3'}>
                <p className={'pb-2'}>
                  <strong>
                    <u>Prévisualisation des ventes annuelles par entreprise</u>
                  </strong>
                </p>
                <HomeTableCalendar year={year} />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default HomeLayout;
