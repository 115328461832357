import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'charges';

export async function IndexCharges() {
  return modelService.IndexModel(model);
}

export async function ShowCharge(id) {
  return modelService.ShowModel(`${model}/${id}`);
}

export async function PostCharge(formData) {
  return modelService.PostModel(`${model}`, formData);
}

export async function PutCharge(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeleteCharge(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
