import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'benefit';

export async function IndexBenefits() {
  return modelService.IndexModel(model);
}

export async function ShowBenefit(id) {
  return modelService.ShowModel(`${model}/${id}`);
}

export async function PostBenefit(formData) {
  return modelService.PostModel(`${model}`, formData);
}

export async function PutBenefit(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeleteBenefit(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
