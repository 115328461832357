import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import HomeMenu from '../home/HomeMenu';
import ViewChargeTable from './ViewChargeTable';
import Flex from '../../common/Flex';
import { IndexCharges } from '../../../services/ChargeService';
import moment from 'moment/moment';

const ViewChargeLayout = () => {
  const [years, setYears] = useState([]);
  const [year, setYear] = useState(0);
  const [charges, setCharges] = useState([]);

  function createArrayYears(charges) {
    let tmpYears = charges
      .map(charge => parseInt(moment(charge.created_at).format('Y')))
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort((a, b) => b - a);
    setYears(tmpYears);
  }

  useEffect(() => {
    const fetchObjects = async () => {
      const responseObjects = await IndexCharges();
      if (responseObjects.success === true) {
        setCharges(responseObjects.data);
        createArrayYears(responseObjects.data);
      }
    };
    fetchObjects();
    setYear(moment().year());
  }, []);

  return (
    <Container fluid={Flex}>
      <Row>
        <Col lg={2} className={'p-0 w-auto'}>
          <HomeMenu />
        </Col>
        <Col className={'main-right'}>
          <Row className={'mt-3'}>
            <Col>
              <Card className={'text-center'}>
                <h3 className={'m-3'}>Gestionnaire des maintenances</h3>
              </Card>
            </Col>
          </Row>
          {charges.length > 0 && years.length > 0 && (
            <>
              <br />
              <Row>
                <Col>
                  <Card className={'p-3'}>
                    <p className={'pb-2'}>
                      <strong>
                        <u>Sélectionner une année</u>
                      </strong>
                    </p>
                    <Form.Group>
                      <Form.Select
                        onChange={e => {
                          setYear(parseInt(e.target.value));
                        }}
                      >
                        {years.map((currentYear, index) => (
                          <option value={currentYear} key={index}>
                            {currentYear}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Card>
                </Col>
              </Row>
            </>
          )}
          <br />
          <ViewChargeTable currentYear={year} />
          <br />
        </Col>
      </Row>
    </Container>
  );
};

export default ViewChargeLayout;
