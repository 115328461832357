import React, { useEffect, useState } from 'react';
import {
  Accordion,
  Card,
  Col,
  FormControl,
  InputGroup,
  Row,
  Table
} from 'react-bootstrap';
import fr from 'date-fns/locale/fr';
import { DeleteWebsite, IndexWebsite } from '../../../services/WebsiteService';
import ActionButton from '../../common/ActionButton';
import { toast } from 'react-toastify';
import ManageWebsite from './manage/ManageWebsite';
import ViewBenefitIntervention from './ViewBenefitIntervention';
import { registerLocale } from 'react-datepicker';
import moment from 'moment/moment';

registerLocale('fr', fr);

const ViewWebsiteTable = () => {
  const [websites, setWebsites] = useState([]);
  const [originalWebSite, setOriginalWebSite] = useState([]);
  const [trigger, setTrigger] = useState(Date.now());
  const [isLoading, setIsLoading] = useState(false);

  const formatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR'
  });

  useEffect(() => {
    const fetchObjects = async () => {
      const responseObjects = await IndexWebsite();
      if (responseObjects.success === true) {
        setWebsites(responseObjects.data);
        setOriginalWebSite(responseObjects.data);
      }
    };
    fetchObjects();
  }, [trigger]);

  const handleDelete = async website => {
    setIsLoading(true);
    const response = await DeleteWebsite(website.id);
    if (response.success === true) {
      setIsLoading(false);
      toast('Site internet supprimée avec succès');
      setTrigger(Date.now());
    } else {
      setIsLoading(false);
    }
  };
  return (
    <Row>
      <Col>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <Card.Title as="h5">Sites internet</Card.Title>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col>
                  <InputGroup>
                    <FormControl
                      placeholder={'Rechercher un site web...'}
                      onChange={e => {
                        if (e.target.value.length > 2) {
                          let search = e.target.value.toLowerCase();
                          setWebsites(
                            originalWebSite.filter(
                              website =>
                                website.frontUrl &&
                                website.frontUrl.toLowerCase().includes(search)
                            )
                          );
                        } else {
                          setWebsites(originalWebSite);
                        }
                      }}
                    />
                  </InputGroup>
                </Col>
                <Col lg={3} style={{ textAlign: 'right' }}>
                  {isLoading === false && (
                    <ManageWebsite
                      setTrigger={setTrigger}
                      action={'add'}
                      selectedObject={{}}
                    />
                  )}
                </Col>
              </Row>

              <br />
              <Row>
                <Col>
                  {websites.length > 0 && (
                    <Table bordered hover responsive>
                      <thead>
                        <tr>
                          <th>Site</th>
                          <th>Url (back office)</th>
                          <th>Note</th>
                          <th>Total de l'année en cours</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {websites.map((website, index) => (
                          <tr key={index}>
                            <td>
                              <a
                                href={website.frontUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {website.frontUrl}
                              </a>
                            </td>
                            <td>
                              <a
                                href={website.backUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {website.backUrl}
                              </a>
                            </td>
                            <td>{website.note}</td>
                            <td>
                              {website.benefits &&
                                website.benefits.length > 0 &&
                                formatter.format(
                                  website.benefits.reduce(
                                    (sumPriceBenefit, object) => {
                                      if (
                                        moment().year() ===
                                        moment(object.billingDateStart).year()
                                      ) {
                                        return (
                                          sumPriceBenefit +
                                          parseFloat(object.amount)
                                        );
                                      } else {
                                        return sumPriceBenefit;
                                      }
                                    },
                                    0
                                  )
                                )}
                            </td>
                            <td>
                              {isLoading === false && (
                                <>
                                  <ViewBenefitIntervention
                                    selectedWebsite={website}
                                    setTriggerTableWebsites={setTrigger}
                                  />
                                  <a href="#" className="cursor-pointer">
                                    <ManageWebsite
                                      setTrigger={setTrigger}
                                      action={'edit'}
                                      selectedObject={website}
                                    />
                                  </a>
                                  <a
                                    href="#"
                                    className="cursor-pointer"
                                    onClick={() => {
                                      handleDelete(website);
                                    }}
                                  >
                                    <ActionButton
                                      icon="trash-alt"
                                      title="Supprimer"
                                      variant="action"
                                      className="p-0"
                                    />
                                  </a>
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </Row>
  );
};

export default ViewWebsiteTable;
