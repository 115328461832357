import React, { useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Offcanvas,
  Row
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import FormAlert from '../../common/FormAlert';
import ActionButton from '../../../common/ActionButton';
import { PostBenefit, PutBenefit } from '../../../../services/BenefitService';

const ManageBenefit = ({
  action,
  setTrigger,
  selectedWebsite,
  selectedBenefit,
  setTriggerTableWebsites
}) => {
  const [showAddBenefit, setShowAddBenefit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [formData, setFormData] = useState({
    web_site_id: selectedWebsite.id,
    amount: '',
    billingDateStart: moment().toDate(),
    billingDateEnd: moment().toDate(),
    check: 0,
    note: ''
  });

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    let response = null;
    let tmpFormData = { ...formData };
    if (tmpFormData.billingDateStart) {
      tmpFormData.billingDateStart = moment(tmpFormData.billingDateStart)
        .local()
        .format('YYYY-MM-DD');
    }
    if (tmpFormData.billingDateEnd) {
      tmpFormData.billingDateEnd = moment(tmpFormData.billingDateEnd)
        .local()
        .format('YYYY-MM-DD');
    }
    if (selectedBenefit && selectedBenefit.id) {
      response = await PutBenefit(selectedBenefit.id, tmpFormData);
    } else {
      response = await PostBenefit(tmpFormData);
    }

    if (response.success === true) {
      setShowAddBenefit(false);
      setIsLoading(false);
      setFormData({
        title: '',
        minutes: ''
      });
      setErrorForm(false);
      toast('Prestation ajoutée avec succès');
      setTrigger(Date.now());
      setTriggerTableWebsites(Date.now());
    } else if (response.validator) {
      setIsLoading(false);
      setErrorForm(response.validator);
    }
  };

  return (
    <>
      {action === 'add' ? (
        <Button
          variant="primary"
          size="sm"
          icon="edit"
          transform="shrink-3"
          disabled={isLoading}
          onClick={() => {
            setShowAddBenefit(true);
          }}
        >
          Ajouter une prestation
        </Button>
      ) : action === 'edit' ? (
        <ActionButton
          icon="edit"
          title="Modifier"
          variant="action"
          className="p-0 me-2"
          onClick={() => {
            setFormData({
              web_site_id: selectedBenefit.web_site_id,
              amount: selectedBenefit.amount,
              billingDateStart: new Date(selectedBenefit.billingDateStart),
              billingDateEnd: new Date(selectedBenefit.billingDateEnd),
              check: selectedBenefit.check,
              note: selectedBenefit.note
            });
            setShowAddBenefit(true);
          }}
        />
      ) : (
        ''
      )}

      <Offcanvas
        show={showAddBenefit}
        onHide={() => {
          setShowAddBenefit(false);
          setFormData({
            web_site_id: '',
            amount: '',
            billingDateStart: '',
            billingDateEnd: '',
            check: '',
            note: ''
          });
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {action === 'add'
              ? 'Ajouter une prestation'
              : 'Modifier la prestation'}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {errorForm && (
            <div className="mt-3">
              <FormAlert variant="danger" data={errorForm} />
            </div>
          )}
          <Col lg={12}>
            <Form.Group>
              <Form.Label>
                Début de la prestation <span>*</span>
              </Form.Label>
              <DatePicker
                onChange={date => {
                  setFormData({
                    ...formData,
                    billingDateStart: date
                  });
                }}
                selected={formData.billingDateStart}
                formatWeekDay={day => day.slice(0, 3)}
                name={'billingDateStart'}
                dateFormat="dd/MM/yy"
                className={'w-auto form-control'}
                locale="fr"
              />
            </Form.Group>
            <Form.Group className={'mt-3'}>
              <Form.Label>
                Fin de la prestation <span>*</span>
              </Form.Label>
              <DatePicker
                onChange={date => {
                  setFormData({
                    ...formData,
                    billingDateEnd: date
                  });
                }}
                selected={formData.billingDateEnd}
                formatWeekDay={day => day.slice(0, 3)}
                name={'billingDateEnd'}
                dateFormat="dd/MM/yy"
                className={'w-auto form-control'}
                locale="fr"
              />
              <Col lg={12} className={'mt-2'}>
                <Button
                  variant="primary"
                  className="me-2 mb-1"
                  onClick={() => {
                    setFormData({
                      ...formData,
                      billingDateEnd: moment(formData.billingDateEnd)
                        .add(1, 'M')
                        .toDate()
                    });
                  }}
                >
                  + 1 mois
                </Button>
                <Button
                  variant="primary"
                  className="me-2 mb-1"
                  onClick={() => {
                    setFormData({
                      ...formData,
                      billingDateEnd: moment(formData.billingDateEnd)
                        .add(6, 'M')
                        .toDate()
                    });
                  }}
                >
                  + 6 mois
                </Button>
                <Button
                  variant="primary"
                  className="me-2 mb-1"
                  onClick={() => {
                    setFormData({
                      ...formData,
                      billingDateEnd: moment(formData.billingDateEnd)
                        .add(1, 'Y')
                        .toDate()
                    });
                  }}
                >
                  + 1 ans
                </Button>
              </Col>
            </Form.Group>
            <Form.Group className={'mt-3'}>
              <Form.Label>
                Prix <span>*</span>
              </Form.Label>
              <InputGroup>
                <FormControl
                  type={'text'}
                  name={'amount'}
                  value={formData.amount}
                  onChange={handleFieldChange}
                ></FormControl>
                <InputGroup.Text>€</InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group className={'mt-3'}>
              <Form.Label>
                Note <span>*</span>
              </Form.Label>
              <FormControl
                as="textarea"
                name={'note'}
                value={formData.note}
                onChange={handleFieldChange}
              />
            </Form.Group>
          </Col>
          <Row className={'mt-3'}>
            <Col lg={12} style={{ textAlign: 'right' }}>
              <a>
                <Button
                  variant="primary"
                  size="sm"
                  icon="edit"
                  transform="shrink-3"
                  disabled={isLoading}
                  onClick={handleSubmit}
                >
                  Enregistrer la prestation
                </Button>
              </a>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

ManageBenefit.propTypes = {
  action: PropTypes.string.isRequired,
  setTrigger: PropTypes.func.isRequired,
  selectedWebsite: PropTypes.object.isRequired,
  selectedBenefit: PropTypes.object,
  setTriggerTableWebsites: PropTypes.func.isRequired
};

export default ManageBenefit;
