import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import IconButton from '../../../common/IconButton';

const ViewClientTable = () => {
  return (
    <Row>
      <Col>
        <Card>
          <p className={'ps-3 pt-3 m-0'}>
            <strong>
              <u>Informations du client</u>
            </strong>
          </p>
          <Card.Body>
            <Card style={{ backgroundColor: '#E6EFFC' }} className={'p-3'}>
              <p>
                <strong>Nom du client :</strong> OVH
              </p>
              <p>
                <strong>SIRET :</strong> 123456789
              </p>
              <p>
                <strong>Adresse :</strong> 2 rue Kellermann
              </p>
              <p>
                <strong>Code postal :</strong> 59100
              </p>
            </Card>
            <Row className={'justify-content-end mt-4 '}>
              <Col lg={3} style={{ textAlign: 'right' }}>
                <IconButton
                  variant="primary"
                  size="sm"
                  icon="edit"
                  transform="shrink-3"
                >
                  Modifier le client
                </IconButton>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ViewClientTable;
