import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { IndexBenefits } from '../../../services/BenefitService';
import moment from 'moment';
import PropTypes from 'prop-types';
import { IndexCompanies } from '../../../services/CompanyService';

const HomeTableCalendar = ({ year }) => {
  const [companies, setCompanies] = useState([]);
  const [benefits, setBenefits] = useState([]);
  const formater = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR'
  });

  useEffect(() => {
    const fetchCompanies = async () => {
      const responseObjects = await IndexCompanies();
      if (responseObjects.success === true) {
        setCompanies(responseObjects.data);
      }
    };
    const fetchBenefits = async () => {
      const responseObjet = await IndexBenefits();
      if (responseObjet.success === true) {
        setBenefits(responseObjet.data);
      }
    };
    fetchCompanies();
    fetchBenefits();
  }, []);
  return (
    <Table bordered hover responsive>
      <thead>
        <tr>
          <th>Total</th>
          <th>
            {formater.format(
              benefits.reduce((accumulator, object) => {
                if (year === moment(object.billingDateStart).year()) {
                  if (moment(object.billingDateStart).month() === 0) {
                    return accumulator + parseFloat(object.amount);
                  } else {
                    return accumulator;
                  }
                } else {
                  return accumulator;
                }
              }, 0)
            )}
          </th>
          <th>
            {formater.format(
              benefits.reduce((accumulator, object) => {
                if (year === moment(object.billingDateStart).year()) {
                  if (moment(object.billingDateStart).month() === 1) {
                    return accumulator + parseFloat(object.amount);
                  } else {
                    return accumulator;
                  }
                } else {
                  return accumulator;
                }
              }, 0)
            )}
          </th>
          <th>
            {formater.format(
              benefits.reduce((accumulator, object) => {
                if (year === moment(object.billingDateStart).year()) {
                  if (moment(object.billingDateStart).month() === 2) {
                    return accumulator + parseFloat(object.amount);
                  } else {
                    return accumulator;
                  }
                } else {
                  return accumulator;
                }
              }, 0)
            )}
          </th>
          <th>
            {formater.format(
              benefits.reduce((accumulator, object) => {
                if (year === moment(object.billingDateStart).year()) {
                  if (moment(object.billingDateStart).month() === 3) {
                    return accumulator + parseFloat(object.amount);
                  } else {
                    return accumulator;
                  }
                } else {
                  return accumulator;
                }
              }, 0)
            )}
          </th>
          <th>
            {formater.format(
              benefits.reduce((accumulator, object) => {
                if (year === moment(object.billingDateStart).year()) {
                  if (moment(object.billingDateStart).month() === 4) {
                    return accumulator + parseFloat(object.amount);
                  } else {
                    return accumulator;
                  }
                } else {
                  return accumulator;
                }
              }, 0)
            )}
          </th>
          <th>
            {formater.format(
              benefits.reduce((accumulator, object) => {
                if (year === moment(object.billingDateStart).year()) {
                  if (moment(object.billingDateStart).month() === 5) {
                    return accumulator + parseFloat(object.amount);
                  } else {
                    return accumulator;
                  }
                } else {
                  return accumulator;
                }
              }, 0)
            )}
          </th>
          <th>
            {formater.format(
              benefits.reduce((accumulator, object) => {
                if (year === moment(object.billingDateStart).year()) {
                  if (moment(object.billingDateStart).month() === 6) {
                    return accumulator + parseFloat(object.amount);
                  } else {
                    return accumulator;
                  }
                } else {
                  return accumulator;
                }
              }, 0)
            )}
          </th>
          <th>
            {formater.format(
              benefits.reduce((accumulator, object) => {
                if (year === moment(object.billingDateStart).year()) {
                  if (moment(object.billingDateStart).month() === 7) {
                    return accumulator + parseFloat(object.amount);
                  } else {
                    return accumulator;
                  }
                } else {
                  return accumulator;
                }
              }, 0)
            )}
          </th>
          <th>
            {formater.format(
              benefits.reduce((accumulator, object) => {
                if (year === moment(object.billingDateStart).year()) {
                  if (moment(object.billingDateStart).month() === 8) {
                    return accumulator + parseFloat(object.amount);
                  } else {
                    return accumulator;
                  }
                } else {
                  return accumulator;
                }
              }, 0)
            )}
          </th>
          <th>
            {formater.format(
              benefits.reduce((accumulator, object) => {
                if (year === moment(object.billingDateStart).year()) {
                  if (moment(object.billingDateStart).month() === 9) {
                    return accumulator + parseFloat(object.amount);
                  } else {
                    return accumulator;
                  }
                } else {
                  return accumulator;
                }
              }, 0)
            )}
          </th>
          <th>
            {formater.format(
              benefits.reduce((accumulator, object) => {
                if (year === moment(object.billingDateStart).year()) {
                  if (moment(object.billingDateStart).month() === 10) {
                    return accumulator + parseFloat(object.amount);
                  } else {
                    return accumulator;
                  }
                } else {
                  return accumulator;
                }
              }, 0)
            )}
          </th>
          <th>
            {formater.format(
              benefits.reduce((accumulator, object) => {
                if (year === moment(object.billingDateStart).year()) {
                  if (moment(object.billingDateStart).month() === 11) {
                    return accumulator + parseFloat(object.amount);
                  } else {
                    return accumulator;
                  }
                } else {
                  return accumulator;
                }
              }, 0)
            )}
          </th>
        </tr>
        <tr>
          <th>Entreprise</th>
          <th>Janv.</th>
          <th>Févr.</th>
          <th>Mars</th>
          <th>Avr.</th>
          <th>Mai</th>
          <th>Juin</th>
          <th>Juill.</th>
          <th>Août</th>
          <th>Sept.</th>
          <th>Oct.</th>
          <th>Nov.</th>
          <th>Déc.</th>
        </tr>
      </thead>
      <tbody>
        {companies.map((compny, index) => (
          <tr key={index}>
            <td>{compny.name}</td>
            <td>
              {formater.format(
                compny.benefits.reduce((accumulator, object) => {
                  if (year === moment(object.billingDateStart).year()) {
                    if (moment(object.billingDateStart).month() === 0) {
                      return accumulator + parseFloat(object.amount);
                    } else {
                      return accumulator;
                    }
                  } else {
                    return accumulator;
                  }
                }, 0)
              )}
            </td>
            <td>
              {formater.format(
                compny.benefits.reduce((accumulator, object) => {
                  if (year === moment(object.billingDateStart).year()) {
                    if (moment(object.billingDateStart).month() === 1) {
                      return accumulator + parseFloat(object.amount);
                    } else {
                      return accumulator;
                    }
                  } else {
                    return accumulator;
                  }
                }, 0)
              )}
            </td>
            <td>
              {formater.format(
                compny.benefits.reduce((accumulator, object) => {
                  if (year === moment(object.billingDateStart).year()) {
                    if (moment(object.billingDateStart).month() === 2) {
                      return accumulator + parseFloat(object.amount);
                    } else {
                      return accumulator;
                    }
                  } else {
                    return accumulator;
                  }
                }, 0)
              )}
            </td>
            <td>
              {formater.format(
                compny.benefits.reduce((accumulator, object) => {
                  if (year === moment(object.billingDateStart).year()) {
                    if (moment(object.billingDateStart).month() === 3) {
                      return accumulator + parseFloat(object.amount);
                    } else {
                      return accumulator;
                    }
                  } else {
                    return accumulator;
                  }
                }, 0)
              )}
            </td>
            <td>
              {formater.format(
                compny.benefits.reduce((accumulator, object) => {
                  if (year === moment(object.billingDateStart).year()) {
                    if (moment(object.billingDateStart).month() === 4) {
                      return accumulator + parseFloat(object.amount);
                    } else {
                      return accumulator;
                    }
                  } else {
                    return accumulator;
                  }
                }, 0)
              )}
            </td>
            <td>
              {formater.format(
                compny.benefits.reduce((accumulator, object) => {
                  if (year === moment(object.billingDateStart).year()) {
                    if (moment(object.billingDateStart).month() === 5) {
                      return accumulator + parseFloat(object.amount);
                    } else {
                      return accumulator;
                    }
                  } else {
                    return accumulator;
                  }
                }, 0)
              )}
            </td>
            <td>
              {formater.format(
                compny.benefits.reduce((accumulator, object) => {
                  if (year === moment(object.billingDateStart).year()) {
                    if (moment(object.billingDateStart).month() === 6) {
                      return accumulator + parseFloat(object.amount);
                    } else {
                      return accumulator;
                    }
                  } else {
                    return accumulator;
                  }
                }, 0)
              )}
            </td>
            <td>
              {formater.format(
                compny.benefits.reduce((accumulator, object) => {
                  if (year === moment(object.billingDateStart).year()) {
                    if (moment(object.billingDateStart).month() === 7) {
                      return accumulator + parseFloat(object.amount);
                    } else {
                      return accumulator;
                    }
                  } else {
                    return accumulator;
                  }
                }, 0)
              )}
            </td>
            <td>
              {formater.format(
                compny.benefits.reduce((accumulator, object) => {
                  if (year === moment(object.billingDateStart).year()) {
                    if (moment(object.billingDateStart).month() === 8) {
                      return accumulator + parseFloat(object.amount);
                    } else {
                      return accumulator;
                    }
                  } else {
                    return accumulator;
                  }
                }, 0)
              )}
            </td>
            <td>
              {formater.format(
                compny.benefits.reduce((accumulator, object) => {
                  if (year === moment(object.billingDateStart).year()) {
                    if (moment(object.billingDateStart).month() === 9) {
                      return accumulator + parseFloat(object.amount);
                    } else {
                      return accumulator;
                    }
                  } else {
                    return accumulator;
                  }
                }, 0)
              )}
            </td>
            <td>
              {formater.format(
                compny.benefits.reduce((accumulator, object) => {
                  if (year === moment(object.billingDateStart).year()) {
                    if (moment(object.billingDateStart).month() === 10) {
                      return accumulator + parseFloat(object.amount);
                    } else {
                      return accumulator;
                    }
                  } else {
                    return accumulator;
                  }
                }, 0)
              )}
            </td>
            <td>
              {formater.format(
                compny.benefits.reduce((accumulator, object) => {
                  if (year === moment(object.billingDateStart).year()) {
                    if (moment(object.billingDateStart).month() === 11) {
                      return accumulator + parseFloat(object.amount);
                    } else {
                      return accumulator;
                    }
                  } else {
                    return accumulator;
                  }
                }, 0)
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
HomeTableCalendar.propTypes = {
  year: PropTypes.number.isRequired
};

export default HomeTableCalendar;
