import React from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';

const ViewClientTable = () => {
  return (
    <Row>
      <Col>
        <Card>
          <p className={'ps-3 pt-3 m-0'}>
            <strong>
              <u>Récapitulatif</u>
            </strong>
          </p>
          <Card.Body>
            <Row>
              <Col>
                <Table bordered responsive>
                  <thead>
                    <tr>
                      <th>Site</th>
                      <th>Url (back office)</th>
                      <th>Prix de la prestation</th>
                      <th>Début prestation</th>
                      <th>Fin prestation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>tredunion.net</td>
                      <td>tredunion.net/admin</td>
                      <td>1 352€</td>
                      <td>19/01/2023</td>
                      <td>19/01/2024</td>
                    </tr>
                    <tr>
                      <td>tredunion.net</td>
                      <td>tredunion.net/admin</td>
                      <td>1 352€</td>
                      <td>19/01/2023</td>
                      <td>19/01/2024</td>
                    </tr>
                    <tr>
                      <td>tredunion.net</td>
                      <td>tredunion.net/admin</td>
                      <td>1 352€</td>
                      <td>19/01/2023</td>
                      <td>19/01/2024</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ViewClientTable;
