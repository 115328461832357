import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'intervention';

export async function IndexInterventions() {
  return modelService.IndexModel(model);
}

export async function ShowIntervention(id) {
  return modelService.ShowModel(`${model}/${id}`);
}

export async function PostIntervention(formData) {
  return modelService.PostModel(`${model}`, formData);
}

export async function PutIntervention(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeleteIntervention(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
