import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormControl,
  Offcanvas,
  Row
} from 'react-bootstrap';
import FormAlert from '../../common/FormAlert';
import { PostCompany, PutCompany } from '../../../../services/CompanyService';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import ActionButton from '../../../common/ActionButton';

const ManageCompany = ({ action, setTrigger, selectedObject }) => {
  const [showAddCompany, setAddShowCompany] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    siret: '',
    city: '',
    address: '',
    zip: ''
  });

  useEffect(() => {
    if (selectedObject.id) {
      setFormData({
        name: selectedObject.name,
        siret: selectedObject.siret,
        city: selectedObject.city,
        address: selectedObject.address,
        zip: selectedObject.zip
      });
    }
  }, []);
  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    let response = null;
    if (selectedObject.id) {
      response = await PutCompany(selectedObject.id, formData);
    } else {
      response = await PostCompany(formData);
    }
    if (response.success === true) {
      setAddShowCompany(false);
      setIsLoading(false);
      setFormData({
        name: '',
        siret: '',
        city: '',
        address: '',
        zip: ''
      });
      setErrorForm(false);
      setTrigger(Date.now());
      toast('Entreprise ajoutée avec succès');
    } else if (response.validator) {
      setIsLoading(false);
      setErrorForm(response.validator);
    }
  };

  return (
    <>
      {action === 'add' ? (
        <Button
          variant="primary"
          size="sm"
          icon="edit"
          transform="shrink-3"
          className={'mb-2'}
          disabled={isLoading}
          onClick={() => {
            setAddShowCompany(true);
          }}
        >
          Ajouter une entreprise
        </Button>
      ) : action === 'edit' ? (
        <ActionButton
          icon="edit"
          title="Modifier"
          variant="action"
          className="p-0 me-2"
          onClick={() => {
            setAddShowCompany(true);
            setFormData({
              name: selectedObject.name,
              siret: selectedObject.siret,
              city: selectedObject.city,
              address: selectedObject.address,
              zip: selectedObject.zip
            });
          }}
        />
      ) : (
        ''
      )}
      <Offcanvas
        show={showAddCompany}
        onHide={() => {
          setAddShowCompany(false);
          setFormData({
            name: '',
            siret: '',
            city: '',
            address: '',
            zip: ''
          });
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {selectedObject.id
              ? "Modifier l'entreprise"
              : 'Ajouter une entreprise'}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {errorForm && (
            <div className="mt-3">
              <FormAlert variant="danger" data={errorForm} />
            </div>
          )}
          <Form.Group>
            <Form.Label>
              Nom de l'entreprise <span>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              onChange={handleFieldChange}
              value={formData.name}
              name={'name'}
            />
          </Form.Group>
          <Form.Group className={'mt-3'}>
            <Form.Label>
              SIRET de l'entreprise <span>*</span>
            </Form.Label>
            <Form.Control
              type={'number'}
              onChange={handleFieldChange}
              value={formData.siret}
              name={'siret'}
            ></Form.Control>
          </Form.Group>
          <Form.Group className={'mt-3'}>
            <Form.Label>
              Ville <span>*</span>
            </Form.Label>
            <FormControl
              type={'text'}
              onChange={handleFieldChange}
              value={formData.city}
              name={'city'}
            ></FormControl>
          </Form.Group>
          <Form.Group className={'mt-3'}>
            <Form.Label>
              Adresse <span>*</span>
            </Form.Label>
            <FormControl
              type={'text'}
              onChange={handleFieldChange}
              value={formData.address}
              name={'address'}
            ></FormControl>
          </Form.Group>
          <Form.Group className={'mt-3'}>
            <Form.Label>
              Code postal <span>*</span>
            </Form.Label>
            <FormControl
              type={'text'}
              onChange={handleFieldChange}
              value={formData.zip}
              name={'zip'}
            ></FormControl>
          </Form.Group>
          <Row className={'mt-3'}>
            <Col lg={12} style={{ textAlign: 'right' }}>
              <Button
                variant="primary"
                size="sm"
                icon="edit"
                transform="shrink-3"
                disabled={isLoading}
                onClick={handleSubmit}
              >
                Enregistrer l'entreprise
              </Button>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

ManageCompany.propTypes = {
  action: PropTypes.string.isRequired,
  setTrigger: PropTypes.func.isRequired,
  selectedObject: PropTypes.object.isRequired
};

export default ManageCompany;
