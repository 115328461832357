import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'company';

export async function IndexCompanies() {
  return modelService.IndexModel(model);
}

export async function ShowCompany(id) {
  return modelService.ShowModel(`${model}/${id}`);
}

export async function PostCompany(formData) {
  return modelService.PostModel(`${model}`, formData);
}

export async function PutCompany(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeleteCompany(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
