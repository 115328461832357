import React from 'react';
import IconButton from '../../common/IconButton';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { PutBenefit } from '../../../services/BenefitService';
import { toast } from 'react-toastify';

const HomeTableExpiry = ({ benefits, setTrigger }) => {
  const handleSubmit = async benefit => {
    let tmpFormData = {
      web_site_id: benefit.web_site_id,
      amount: benefit.amount,
      billingDateStart: new Date(benefit.billingDateStart),
      billingDateEnd: new Date(benefit.billingDateEnd),
      check: 1,
      note: benefit.note
    };
    let response = null;
    if (tmpFormData.billingDateStart) {
      tmpFormData.billingDateStart = moment(tmpFormData.billingDateStart)
        .local()
        .format('YYYY-MM-DD');
    }
    if (tmpFormData.billingDateEnd) {
      tmpFormData.billingDateEnd = moment(tmpFormData.billingDateEnd)
        .local()
        .format('YYYY-MM-DD');
    }
    response = await PutBenefit(benefit.id, tmpFormData);

    if (response.success === true) {
      toast('La facture a été envoyée avec succès !');
      setTrigger(Date.now());
    }
  };

  const filterBenefits = () => {
    const filteredBenefits = benefits.filter(benefit => {
      return (
        moment(benefit.billingDateEnd).local().diff(moment(), 'days') <= 60 &&
        benefit.check === 0
      );
    });
    return filteredBenefits;
  };

  return (
    <>
      {benefits && benefits.length > 0 && (
        <Table bordered hover responsive>
          <thead>
            <tr>
              <th>Date d’expiration</th>
              <th>Nom de l'entreprise</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filterBenefits().map((benefit, index) => (
              <tr key={index}>
                <td>
                  {moment(benefit.billingDateEnd).local().format('DD/MM/YYYY')}
                </td>
                <td>{benefit.company.name}</td>
                <td>
                  <a
                    onClick={() => {
                      handleSubmit(benefit);
                    }}
                  >
                    <IconButton
                      variant="primary"
                      size="sm"
                      icon="check"
                      transform="shrink-3"
                    >
                      Facture envoyée
                    </IconButton>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

HomeTableExpiry.propTypes = {
  benefits: PropTypes.array.isRequired,
  setTrigger: PropTypes.func.isRequired
};

export default HomeTableExpiry;
