import React, { useEffect, useState } from 'react';
import {
  Accordion,
  Card,
  Col,
  Form,
  Offcanvas,
  Row,
  Table
} from 'react-bootstrap';
import moment from 'moment';
import {
  DeleteIntervention,
  IndexInterventions
} from '../../../services/InterventionService';
import { toast } from 'react-toastify';
import { DeleteBenefit, IndexBenefits } from '../../../services/BenefitService';
import ActionButton from '../../common/ActionButton';
import ManageBenefit from './manage/ManageBenefit';
import ManageIntervention from './manage/ManageIntervention';
import PropTypes from 'prop-types';

const ViewBenefitIntervention = ({
  selectedWebsite,
  setTriggerTableWebsites
}) => {
  const [benefits, setBenefits] = useState([]);
  const [interventions, setInterventions] = useState([]);
  const [showInfosWebsite, setShowInfosWebsite] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [trigger, setTrigger] = useState(Date.now());
  const [years, setYears] = useState([]);
  const [year, setYear] = useState(0);
  const formatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR'
  });

  function createArrayYears() {
    let tmpYears = interventions
      .map(intervention =>
        parseInt(moment(intervention.created_at).format('Y'))
      )
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort((a, b) => b - a);
    setYears(tmpYears);
  }

  const handleDeleteIntervention = async intervention => {
    setIsLoading(true);
    const response = await DeleteIntervention(intervention.id);
    if (response.success === true) {
      setIsLoading(false);
      toast('Intervention supprimée avec succès');
      setTrigger(Date.now());
    } else {
      setIsLoading(false);
    }
  };

  const handleDeleteBenefit = async intervention => {
    setIsLoading(true);
    const response = await DeleteBenefit(intervention.id);
    if (response.success === true) {
      setIsLoading(false);
      toast('Prestation supprimée avec succès');
      setTrigger(Date.now());
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchInterventions = async () => {
      const responseObjects = await IndexInterventions();
      if (responseObjects.success === true) {
        setInterventions(
          responseObjects.data.filter(d => d.web_site_id === selectedWebsite.id)
        );
      }
    };

    const fetchBenefits = async () => {
      const responseObjects = await IndexBenefits();
      if (responseObjects.success === true) {
        setBenefits(
          responseObjects.data.filter(d => d.web_site_id === selectedWebsite.id)
        );
      }
    };

    fetchBenefits();
    fetchInterventions();
    setYear(moment().year());
    createArrayYears();
  }, [trigger]);

  return (
    <>
      <a
        onClick={() => {
          setShowInfosWebsite(true);
        }}
      >
        <ActionButton
          icon="eye"
          title="Voir"
          variant="action"
          className="p-0 me-2"
        />
      </a>
      <Offcanvas
        show={showInfosWebsite}
        onShow={() => {
          createArrayYears(selectedWebsite);
        }}
        onHide={() => {
          setShowInfosWebsite(false);
          setYears([]);
        }}
        placement="end"
        className={'w-75'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Informations site internet</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <Card.Title as={'h5'}>Prestations</Card.Title>
              </Accordion.Header>
              <Accordion.Body>
                <Row>
                  <Col>
                    <p>
                      <strong>
                        <u>Visualisation des prestations</u>
                      </strong>
                    </p>
                  </Col>
                  <Col lg={3} style={{ textAlign: 'right' }}>
                    {isLoading === false && (
                      <a>
                        <ManageBenefit
                          setTrigger={setTrigger}
                          action={'add'}
                          selectedWebsite={selectedWebsite}
                          selectedIntervention={{}}
                          setTriggerTableWebsites={setTriggerTableWebsites}
                        />
                      </a>
                    )}
                  </Col>
                </Row>
                <br />
                {selectedWebsite.id && benefits.length > 0 && (
                  <Row>
                    <Col>
                      <Table bordered hover responsive>
                        <thead>
                          <tr>
                            <th>Prix</th>
                            <th>Début de la prestation</th>
                            <th>Fin de la prestation</th>
                            <th>Note</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {benefits.map((benefit, index) => (
                            <tr key={index}>
                              <td>{formatter.format(benefit.amount)}</td>
                              <td>
                                {moment(benefit.billingDateStart)
                                  .local()
                                  .format('DD/MM/YYYY')}
                              </td>
                              <td>
                                {moment(benefit.billingDateEnd)
                                  .local()
                                  .format('DD/MM/YYYY')}
                              </td>
                              <td>{benefit.note}</td>
                              <td>
                                {isLoading === false && (
                                  <>
                                    <ManageBenefit
                                      setTrigger={setTrigger}
                                      action={'edit'}
                                      selectedBenefit={benefit}
                                      selectedWebsite={selectedWebsite}
                                      setTriggerTableWebsites={
                                        setTriggerTableWebsites
                                      }
                                    />
                                    <a
                                      href="#"
                                      className="cursor-pointer"
                                      onClick={() => {
                                        handleDeleteBenefit(benefit);
                                      }}
                                    >
                                      <ActionButton
                                        icon="trash-alt"
                                        title="Supprimer"
                                        variant="action"
                                        className="p-0"
                                      />
                                    </a>
                                  </>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <br />
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <Card.Title as={'h5'}>Interventions</Card.Title>
              </Accordion.Header>
              <Accordion.Body>
                <Row>
                  <Col>
                    <p>
                      <strong>
                        <u>Visualisation des interventions</u>
                      </strong>
                    </p>
                  </Col>
                  <Col lg={3} style={{ textAlign: 'right' }}>
                    {isLoading === false && (
                      <a>
                        <ManageIntervention
                          setTrigger={setTrigger}
                          action={'add'}
                          selectedWebsite={selectedWebsite}
                          selectedIntervention={{}}
                        />
                      </a>
                    )}
                  </Col>
                </Row>
                <br />
                {years.length > 0 &&
                  interventions &&
                  interventions.length > 0 && (
                    <Row>
                      <Col>
                        <Card className={'p-3 mb-3'}>
                          <p className={'pb-2'}>
                            <strong>
                              <u>Sélectionner une année</u>
                            </strong>
                          </p>
                          <Form.Group>
                            <Form.Select
                              onChange={e => {
                                setYear(parseInt(e.target.value));
                              }}
                            >
                              {years.map((currentYear, index) => (
                                <option value={currentYear} key={index}>
                                  {currentYear}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </Card>
                      </Col>
                    </Row>
                  )}
                {selectedWebsite.id &&
                  interventions &&
                  interventions.length > 0 && (
                    <Row>
                      <Col>
                        <Table bordered hover responsive>
                          <thead>
                            <tr>
                              <th>Total annuel</th>
                              <th>
                                {interventions.reduce((accumulator, object) => {
                                  if (
                                    year === moment(object.created_at).year()
                                  ) {
                                    return accumulator + object.minutes;
                                  } else {
                                    return accumulator;
                                  }
                                }, 0)}
                                min
                              </th>
                              <th>
                                {formatter.format(
                                  interventions.reduce(
                                    (accumulator, object) => {
                                      if (
                                        year ===
                                        moment(object.created_at).year()
                                      ) {
                                        return (
                                          accumulator + object.minutes * 1.19
                                        );
                                      } else {
                                        return accumulator;
                                      }
                                    },
                                    0
                                  )
                                )}
                              </th>
                            </tr>
                            <tr>
                              <th>Titre</th>
                              <th>Minutes</th>
                              <th>Prix calculé</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {interventions
                              .filter(
                                intervention =>
                                  year ===
                                  moment(intervention.created_at).year()
                              )
                              .map((intervention, index) => (
                                <tr key={index}>
                                  <td>{intervention.title}</td>
                                  <td>{intervention.minutes}</td>
                                  <td>
                                    {formatter.format(
                                      intervention.minutes * 1.19
                                    )}
                                  </td>
                                  <td>
                                    {isLoading === false && (
                                      <>
                                        <ManageIntervention
                                          setTrigger={setTrigger}
                                          action={'edit'}
                                          selectedWebsite={selectedWebsite}
                                          selectedIntervention={intervention}
                                        />
                                        <a
                                          href="#"
                                          className="cursor-pointer"
                                          onClick={() => {
                                            handleDeleteIntervention(
                                              intervention
                                            );
                                          }}
                                        >
                                          <ActionButton
                                            icon="trash-alt"
                                            title="Supprimer"
                                            variant="action"
                                            className="p-0"
                                          />
                                        </a>
                                      </>
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

ViewBenefitIntervention.propTypes = {
  selectedWebsite: PropTypes.object.isRequired,
  setTriggerTableWebsites: PropTypes.func.isRequired
};

export default ViewBenefitIntervention;
