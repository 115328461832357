import React, { useState } from 'react';
import { Button, Form, Col, Offcanvas, Row } from 'react-bootstrap';
import ActionButton from '../../../common/ActionButton';
import PropTypes from 'prop-types';
import {
  PostIntervention,
  PutIntervention
} from '../../../../services/InterventionService';
import { toast } from 'react-toastify';
import FormAlert from '../../common/FormAlert';

const ManageIntervention = ({
  action,
  setTrigger,
  selectedWebsite,
  selectedIntervention
}) => {
  const [showAddIntervention, setShowAddIntervention] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [formData, setFormData] = useState({
    web_site_id: selectedWebsite.id,
    title: '',
    minutes: ''
  });

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    let response = null;
    if (selectedIntervention && selectedIntervention.id) {
      response = await PutIntervention(selectedIntervention.id, formData);
    } else {
      response = await PostIntervention(formData);
    }
    if (response.success === true) {
      setShowAddIntervention(false);
      setIsLoading(false);
      setFormData({
        ...formData,
        title: '',
        minutes: ''
      });
      setErrorForm(false);
      toast('Intervention ajoutée avec succès');
      setTrigger(Date.now());
    } else if (response.validator) {
      setIsLoading(false);
      setErrorForm(response.validator);
    }
  };

  return (
    <>
      {action === 'add' ? (
        <Button
          variant="primary"
          size="sm"
          icon="edit"
          transform="shrink-3"
          disabled={isLoading}
          onClick={() => {
            setShowAddIntervention(true);
          }}
        >
          Ajouter une intervention
        </Button>
      ) : action === 'edit' ? (
        <ActionButton
          icon="edit"
          title="Modifier"
          variant="action"
          className="p-0 me-2"
          onClick={() => {
            setFormData({
              web_site_id: selectedIntervention.web_site_id,
              title: selectedIntervention.title,
              minutes: selectedIntervention.minutes
            });
            setShowAddIntervention(true);
          }}
        />
      ) : (
        ''
      )}
      <Offcanvas
        show={showAddIntervention}
        onHide={() => {
          setShowAddIntervention(false);
          setFormData({
            ...formData,
            title: '',
            minutes: ''
          });
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {action === 'add'
              ? 'Ajouter une intervention'
              : "Modifier l'intervention"}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {errorForm && (
            <div className="mt-3">
              <FormAlert variant="danger" data={errorForm} />
            </div>
          )}
          <Col lg={12}>
            <Form.Group>
              <Form.Label>
                Titre de l'intervention <span>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                onChange={handleFieldChange}
                value={formData.title}
                name={'title'}
              />
            </Form.Group>
            <Form.Group className={'mt-3'}>
              <Form.Label>
                Temps en minutes <span>*</span>
              </Form.Label>
              <Form.Control
                type={'number'}
                onChange={handleFieldChange}
                value={formData.minutes}
                name={'minutes'}
              ></Form.Control>
            </Form.Group>
          </Col>
          <Row className={'mt-3'}>
            <Col lg={12} style={{ textAlign: 'right' }}>
              <a>
                <Button
                  variant="primary"
                  size="sm"
                  icon="edit"
                  transform="shrink-3"
                  disabled={isLoading}
                  onClick={handleSubmit}
                >
                  Enregistrer l'intervention
                </Button>
              </a>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

ManageIntervention.propTypes = {
  action: PropTypes.string.isRequired,
  setTrigger: PropTypes.func.isRequired,
  selectedWebsite: PropTypes.object.isRequired,
  selectedIntervention: PropTypes.object
};

export default ManageIntervention;
