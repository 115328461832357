import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import HomeMenu from '../home/HomeMenu';
import Flex from '../../common/Flex';
import ViewClientTable from './ViewClientTable';

const ViewClientLayout = () => (
  <Container fluid={Flex}>
    <Row>
      <Col lg={2} className={'p-0 w-auto'}>
        <HomeMenu />
      </Col>
      <Col className={'main-right'}>
        <Row className={'mt-3'}>
          <Col>
            <Card className={'text-center'}>
              <h3 className={'m-3'}>Gestionnaire des maintenances</h3>
            </Card>
          </Col>
        </Row>
        <br />
        <ViewClientTable />
        <br />
      </Col>
    </Row>
  </Container>
);

export default ViewClientLayout;
