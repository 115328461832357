import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import is from 'is_js';

import ErrorLayout from './ErrorLayout';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import AppContext from 'context/Context';

import HomeLayout from '../components/gestionnaire_de_prestations/home/HomeLayout';
import ViewClientSingleLayout from '../components/gestionnaire_de_prestations/client/single/ViewClientSingleLayout';
import ViewWebsiteLayout from '../components/gestionnaire_de_prestations/website/ViewWebsiteLayout';
import ViewChargeLayout from '../components/gestionnaire_de_prestations/charge/ViewChargeLayout';

import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';
import ViewClientLayout from '../components/gestionnaire_de_prestations/client/ViewClientLayout';
import LoginLayout from '../components/gestionnaire_de_prestations/layouts/LoginLayout';
import AdminLayout from '../components/gestionnaire_de_prestations/layouts/AdminLayout';
import LoginQwenty from '../components/gestionnaire_de_prestations/auth/Login';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      <Routes>
        <Route element={<LoginLayout />}>
          <Route path="login" element={<LoginQwenty />} />
        </Route>
        <Route element={<AdminLayout />}>
          <Route path="" element={<HomeLayout />} />
          <Route path="client" element={<ViewClientLayout />} />
          <Route path="client-single" element={<ViewClientSingleLayout />} />
          <Route path="website" element={<ViewWebsiteLayout />} />
          <Route path="charge" element={<ViewChargeLayout />} />
        </Route>
        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>
        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>

      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.TOP_RIGHT}
      />
    </>
  );
};

export default Layout;
