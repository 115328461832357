import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Card,
  Table,
  FormControl,
  InputGroup,
  Accordion
} from 'react-bootstrap';
import { DeleteCharge, IndexCharges } from '../../../services/ChargeService';
import { toast } from 'react-toastify';
import ActionButton from '../../common/ActionButton';
import ManageCharge from './manage/ManageCharge';
import PropTypes from 'prop-types';
import moment from 'moment/moment';

const ViewChargeTable = ({ currentYear }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [charges, setCharges] = useState([]);
  const [originalCharges, setOriginalCharges] = useState([]);
  const [trigger, setTrigger] = useState(Date.now());

  const formatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR'
  });

  useEffect(() => {
    const fetchObjects = async () => {
      const responseObjects = await IndexCharges();
      if (responseObjects.success === true) {
        setCharges(responseObjects.data);
        setOriginalCharges(responseObjects.data);
      }
    };
    fetchObjects();
  }, [trigger]);

  const handleDeleteCharge = async charge => {
    setIsLoading(true);
    const response = await DeleteCharge(charge.id);
    if (response.success === true) {
      setIsLoading(false);
      toast('Charge supprimée avec succès');
      setTrigger(Date.now());
    } else {
      setIsLoading(false);
    }
  };

  return (
    <Row>
      <Col>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <Card.Title as="h5">Charges</Card.Title>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col>
                  <InputGroup>
                    <FormControl
                      placeholder={'Rechercher une charge...'}
                      onChange={e => {
                        if (e.target.value.length > 2) {
                          let search = e.target.value.toLowerCase();
                          setCharges(
                            originalCharges.filter(
                              charge =>
                                charge.title &&
                                charge.title.toLowerCase().includes(search)
                            )
                          );
                        } else {
                          setCharges(originalCharges);
                        }
                      }}
                    />
                  </InputGroup>
                </Col>
                <Col lg={3} style={{ textAlign: 'right' }}>
                  {isLoading === false && (
                    <ManageCharge
                      setTrigger={setTrigger}
                      action={'add'}
                      selectedObject={{}}
                    />
                  )}
                </Col>
              </Row>
              <br />
              {charges.length > 0 && (
                <Table bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Total annuel :</th>
                      <th>
                        {formatter.format(
                          charges.reduce((accumulator, object) => {
                            if (
                              currentYear === moment(object.created_at).year()
                            ) {
                              if (object.frequency === 'monthly') {
                                return (
                                  accumulator + parseFloat(object.amount) * 12
                                );
                              } else {
                                return accumulator + parseFloat(object.amount);
                              }
                            } else {
                              return accumulator;
                            }
                          }, 0)
                        )}
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th>Titre</th>
                      <th>Prix</th>
                      <th>Fréquence</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {charges
                      .filter(
                        charge =>
                          currentYear === moment(charge.created_at).year()
                      )
                      .map((charge, index) => (
                        <tr key={index}>
                          <td>{charge.title}</td>
                          <td>{formatter.format(charge.amount)}</td>
                          <td>
                            {charge.frequency === 'monthly'
                              ? 'Mensuel'
                              : 'Annuel'}
                          </td>
                          <td>
                            {isLoading === false && (
                              <>
                                <a href="#" className="cursor-pointer">
                                  <ManageCharge
                                    setTrigger={setTrigger}
                                    action={'edit'}
                                    selectedObject={charge}
                                  />
                                </a>
                                <a
                                  href="#"
                                  className="cursor-pointer"
                                  onClick={() => {
                                    handleDeleteCharge(charge);
                                  }}
                                >
                                  <ActionButton
                                    icon="trash-alt"
                                    title="Supprimer"
                                    variant="action"
                                    className="p-0"
                                  />
                                </a>
                              </>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </Row>
  );
};

ViewChargeTable.propTypes = {
  currentYear: PropTypes.number.isRequired
};

export default ViewChargeTable;
