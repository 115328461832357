import { toast } from 'react-toastify';

export default function RequireAuthAdmin(navigate) {
  if (
    !localStorage.getItem('accessTokenQwenty') &&
    !localStorage.getItem('userQwenty')
  ) {
    toast('Veuillez vous connecter pour accéder au contenu');
    navigate('/login');
  }
}
