import React, { useEffect, useState } from 'react';
import {
  Accordion,
  Card,
  Col,
  FormControl,
  InputGroup,
  Row,
  Table
} from 'react-bootstrap';
import {
  DeleteCompany,
  IndexCompanies
} from '../../../services/CompanyService';
import ActionButton from '../../common/ActionButton';
import { toast } from 'react-toastify';
import ManageCompany from './manage/ManageCompany';

const ViewClientTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [originalCompanies, setOriginalCompanies] = useState([]);
  const [trigger, setTrigger] = useState(Date.now());

  useEffect(() => {
    const fetchObjects = async () => {
      const responseObjects = await IndexCompanies();
      if (responseObjects.success === true) {
        setCompanies(responseObjects.data);
        setOriginalCompanies(responseObjects.data);
      }
    };
    fetchObjects();
  }, [trigger]);
  const handleDelete = async company => {
    setIsLoading(true);
    const response = await DeleteCompany(company.id);
    if (response.success === true) {
      setIsLoading(false);
      toast('Entreprise supprimée avec succès');
      setTrigger(Date.now());
    } else {
      setIsLoading(false);
    }
  };

  return (
    <Row>
      <Col>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <Card.Title as="h5">Entreprises</Card.Title>
            </Accordion.Header>
            <Accordion.Body>
              <Row className={'justify-content-end'}>
                <Col>
                  <InputGroup>
                    <FormControl
                      placeholder={'Rechercher une entreprise...'}
                      onChange={e => {
                        if (e.target.value.length > 2) {
                          let search = e.target.value.toLowerCase();
                          setCompanies(
                            originalCompanies.filter(
                              company =>
                                company.name &&
                                company.name.toLowerCase().includes(search)
                            )
                          );
                        } else {
                          setCompanies(originalCompanies);
                        }
                      }}
                    />
                  </InputGroup>
                </Col>
                <Col lg={3} style={{ textAlign: 'right' }}>
                  {isLoading === false && (
                    <ManageCompany
                      setTrigger={setTrigger}
                      selectedObject={{}}
                      action={'add'}
                    />
                  )}
                </Col>
              </Row>

              <br />
              <Row>
                <Col>
                  {companies.length > 0 && (
                    <Table bordered hover responsive>
                      <thead>
                        <tr>
                          <th>Nom de l'entreprise</th>
                          <th>SIRET</th>
                          <th>Ville</th>
                          <th>Code postal</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {companies.map((company, index) => (
                          <tr key={index}>
                            <td>{company.name}</td>
                            <td>{company.siret}</td>
                            <td>{company.city}</td>
                            <td>{company.zip}</td>
                            <td>
                              {isLoading === false && (
                                <>
                                  <a href="#" className="cursor-pointer">
                                    <ManageCompany
                                      setTrigger={setTrigger}
                                      action={'edit'}
                                      selectedObject={company}
                                    />
                                  </a>
                                  <a
                                    href="#"
                                    className="cursor-pointer"
                                    onClick={() => {
                                      handleDelete(company);
                                    }}
                                  >
                                    <ActionButton
                                      icon="trash-alt"
                                      title="Supprimer"
                                      variant="action"
                                      className="p-0"
                                    />
                                  </a>
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </Row>
  );
};

export default ViewClientTable;
