import React, { useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Offcanvas,
  Row
} from 'react-bootstrap';
import FormAlert from '../../common/FormAlert';
import PropTypes from 'prop-types';
import { PostCharge, PutCharge } from '../../../../services/ChargeService';
import { toast } from 'react-toastify';
import ActionButton from '../../../common/ActionButton';

const ManageCharge = ({ action, setTrigger, selectedObject }) => {
  const [showAddCharge, setShowAddCharge] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    amount: '',
    frequency: 'monthly'
  });

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    let response = null;
    if (selectedObject.id) {
      response = await PutCharge(selectedObject.id, formData);
    } else {
      response = await PostCharge(formData);
    }
    if (response.success === true) {
      setShowAddCharge(false);
      setIsLoading(false);
      setFormData({
        title: '',
        amount: '',
        frequency: 'monthly'
      });
      setErrorForm(false);
      toast('Charge ajoutée avec succès');
      setTrigger(Date.now());
    } else if (response.validator) {
      setIsLoading(false);
      setErrorForm(response.validator);
    }
  };

  return (
    <>
      {action === 'add' ? (
        <Button
          variant="primary"
          size="sm"
          icon="edit"
          transform="shrink-3"
          disabled={isLoading}
          onClick={() => {
            setShowAddCharge(true);
          }}
        >
          Ajouter une charge
        </Button>
      ) : action === 'edit' ? (
        <ActionButton
          icon="edit"
          title="Modifier"
          variant="action"
          className="p-0 me-2"
          onClick={() => {
            setShowAddCharge(true);
            setFormData({
              title: selectedObject.title,
              amount: selectedObject.amount,
              frequency: selectedObject.frequency
            });
          }}
        />
      ) : (
        ''
      )}
      <Offcanvas
        show={showAddCharge}
        onHide={() => {
          setShowAddCharge(false);
          setFormData({
            title: '',
            amount: '',
            frequency: 'monthly'
          });
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {selectedObject.id ? 'Modifier la charge' : 'Ajouter une charge'}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {errorForm && (
            <div className="mt-3">
              <FormAlert variant="danger" data={errorForm} />
            </div>
          )}
          <Form.Group>
            <Form.Label>
              Titre <span>*</span>
            </Form.Label>
            <FormControl
              type={'text'}
              value={formData.title}
              name="title"
              onChange={handleFieldChange}
            ></FormControl>
          </Form.Group>
          <Form.Group className={'mt-3'}>
            <Form.Label>
              Fréquence <span>*</span>
            </Form.Label>
            <Form.Select
              value={formData.frequency}
              name="frequency"
              onChange={handleFieldChange}
            >
              <option value="monthly">Mensuel</option>
              <option value="yearly">Annuel</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className={'mt-3'}>
            <Form.Label>
              Montant <span>*</span>
            </Form.Label>
            <InputGroup>
              <FormControl
                id={'inputPrice'}
                type="number"
                value={formData.amount}
                name="amount"
                onChange={handleFieldChange}
              />
              <InputGroup.Text>€</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Row className={'mt-3'}>
            <Col lg={12} style={{ textAlign: 'right' }}>
              <Button
                variant="primary"
                size="sm"
                icon="edit"
                transform="shrink-3"
                disabled={isLoading}
                onClick={handleSubmit}
              >
                Enregistrer la charge
              </Button>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

ManageCharge.propTypes = {
  action: PropTypes.string.isRequired,
  setTrigger: PropTypes.func.isRequired,
  selectedObject: PropTypes.object.isRequired
};

export default ManageCharge;
